<template lang="pug">
.date-input-wrapper.w-100(:class="{ small }")
  input-field.countdownFlatpickr-date(
    :value="value"
    :placeholder="placeholder"
    readonly
    :invalid="invalid"
    :small="small"
  )
    UilCalender(slot="icon" :size="small ? '1.5em' : '1.715em'")
</template>
<script>
  import moment from 'moment';
  import itemMixin from '@/editor/mixins/item';
  import { UilCalender } from '@iconscout/vue-unicons';
  import flatpickr from 'flatpickr';
  import 'flatpickr/dist/flatpickr.min.css';
  import { mapMutations } from 'vuex';
  import sidebarInputMixin from '@/mixins/sidebar/input';

  const FORMAT = 'YYYY/MM/DD';

  export default {
    components: {
      UilCalender,
      InputField: () => import('@/editor/components/sidebar/components/InputField.vue'),
    },
    mixins: [itemMixin, sidebarInputMixin],
    props: {
      property: { type: String, required: true },
      small: { type: Boolean, default: false },
    },
    data: () => ({
      flatpickrInstance: null,
    }),
    computed: {
      value() {
        const propValue = this.smartGetValueOf(this.property);
        return propValue ? moment(propValue).format(FORMAT) : '';
      },
      placeholder() {
        return FORMAT;
      },
    },
    mounted() {
      setTimeout(() => {
        this.initFlatpickr();
      }, 400);
    },
    beforeDestroy() {
      if (this.flatpickrInstance !== null) {
        this.flatpickrInstance.close();
        this.flatpickrInstance.destroy();
      }
      this.$store.commit('changeCalendarVisibility', 'hidden');
    },
    methods: {
      ...mapMutations(['updateData', 'updateStyle']),
      initFlatpickr() {
        const updateFn = function (dates) {
          const currentDate = new Date();
          const date = dates[0].getTime() < currentDate.getTime() ? currentDate : dates[0];
          this.updateData({
            property: 'selectedElement.data.countdown.endDate.date',
            value: date.toUTCString(),
          });
          this.$emit('change');
        }.bind(this);
        this.flatpickrInstance = flatpickr('.countdownFlatpickr-date', {
          noCalendar: false,
          enableTime: false,
          time_24hr: true,
          // minDate: 'today',
          maxDate: new Date().fp_incr(99),
          dateFormat: 'YYYY/MM/DD H:i',
          onValueUpdate: updateFn,
          onChange: updateFn,
          onOpen: this.$store.commit('changeCalendarVisibility', 'visible'),
        });
      },
    },
  };
</script>

<style lang="sass" scoped>
  .countdownFlatpickr-date
    ::v-deep.epic-simple-input
      input
        position: absolute
      .epic-simple-input-icon
        position: relative
        display: flex
        justify-content: flex-end
        top: 5px
        right: 5px
      .small
        &.epic-simple-input-icon
          top: 2px
</style>
